import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { sha256 } from "js-sha256";
import "../../App.css";
import "./SignUp.css";
import axios from "axios";
import Header from "../../components/Header/Header";
import HeaderHomePage from "../../components/HeaderHomepage/HeaderHomepage";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import "reactjs-popup/dist/index.css";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import AboutHelper from "../../components/AboutHelper/AboutHelper";

// import login from "../login/login.component";

const Signup = () => {
  let history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [nameSignUpFriend, setNameSignUpFriend] = useState("");
  const [cellSignUpFriend, setCellSignUpFriend] = useState("");
  const [myFamily, setMyFamily] = useState(true);
  const [isThePersonKnow, setIsThePersonKnow] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState("");
  const [isTermsAndConditionsAgreed, setIsTermsAndConditionsAgreed] =
    useState(false);
  const [referToWeb, setReferToWeb] = useState("");

  const [isAboutDisplayed, setIsAboutDisplayed] = useState(true);
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [isErrorPasswordDisplayed, setIsErrorPasswordDisplayed] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPassMsg, setErrorPassMsg] = useState("");
  const [isTermsAndConditionsDisplayed, setIsTermsAndConditionsDisplayed] =
    useState(false);
  const [isSignUpForAnother, setIsSignUpForAnother] = useState(false);

  // Only Hebrew or English cherecters allowed
  const HebrewValidatorRegex = /^[\u05D0-\u05EA ]+$/i;
  const EnglishValidatorRegex = /^[a-zA-Z ]+$/i;
  const MaxNameCherecters = 15;
  const MinNameCherecters = 2;
  const maxPasswordLength = 16;
  const minPasswordLength = 8;

  const israelPhoneValidatorRegex =
    /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/i;

  const passwordValidatorRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/i;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    Cookies.remove("userId");
  }, []);

  const handleGender = (val) => {
    setGender(val);
  };

  const nameValidator = (name) => {
    if (name.length <= MaxNameCherecters && name.length >= MinNameCherecters) {
      if (
        HebrewValidatorRegex.exec(name) !== null ||
        EnglishValidatorRegex.exec(name) !== null
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const phoneValidator = (phoneNumber) => {
    return israelPhoneValidatorRegex.exec(phoneNumber) !== null;
  };

  const onClickRelative = () => {
    setMyFamily(!myFamily);
  };

  const passwordValidator = (password) => {
    if (
      password.length <= maxPasswordLength &&
      password.length >= minPasswordLength
    ) {
      if (passwordValidatorRegex.exec(password) !== null) {
        return true;
      }
    } else {
      return false;
    }
  };

  const confirmValidator = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const emailValidator = (email) => {
    const emailValidatorRegex =
      /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/m;
    const regex = new RegExp(emailValidatorRegex);

    return email.match(regex);
  };

  const genderSelectedValidatpr = () => {
    return gender != "";
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const onSubmit = async () => {
    if ((isSignUpForAnother && isThePersonKnow) || !isSignUpForAnother) {
      if (nameValidator(nameSignUpFriend.trim()) || !isSignUpForAnother) {
        if (
          (phoneValidator(cellSignUpFriend.trim()) &&
            cellSignUpFriend.trim().length > 9) ||
          !isSignUpForAnother
        ) {
          if (nameValidator(firstName.trim())) {
            if (nameValidator(lastName.trim())) {
              if (
                phoneValidator(telephone.trim()) &&
                telephone.trim().length > 9
              ) {
                if (emailValidator(email.trim())) {
                  if (passwordValidator(password)) {
                    if (confirmValidator(password, confirmPassword)) {
                      if (referToWeb.length < 46) {
                        if (genderSelectedValidatpr()) {
                          if (isTermsAndConditionsAgreed) {
                            const response = await axios.post(
                              process.env.REACT_APP_LOCALHOST_ADDRESS +
                                "/PsignUp",
                              {
                                firstName: firstName.trim(),
                                lastName: lastName.trim(),
                                telephone: telephone.trim(),
                                password: sha256(password),
                                gender,
                                email,
                                referToWeb,
                                nameSignUpFriend,
                                cellSignUpFriend,
                                myFamily: myFamily ? 1 : 2,
                              },
                              config
                            );

                            if (response.data !== false) {
                              const userId = response.data[0][0].userId;
                              localStorage.setItem("newUser", 1);
                              history.push("/aboutme");
                              // setIsErrorMessageDisplayed(false);
                            } else {
                              alertUser(
                                "מס הטלפון או כתובת האימייל כבר בשימוש"
                              );
                              console.log("User is already exists");
                            }
                          } else {
                            alertUser(
                              "על מנת להשתמש באתר עליך להסכים לתנאי השימוש"
                            );
                            console.log("Terms and conditions not approved");
                          }
                        } else {
                          alertUser("לא בחרת מגדר");
                          console.log("gender not selceted");
                        }
                      } else {
                        alertUser("דרך מי הגעת ארוך מידי");
                        console.log("refer too long");
                      }
                    } else {
                      alertUser("הסיסמאות אינן תואמות");
                      console.log("confirm Password is not valid");
                    }
                  } else {
                    alertUser(
                      `סיסמה לא תקינה, הסיסמה חייבת להיות בין ${minPasswordLength} ל ${maxPasswordLength} תווים, עם מספר ואות אחת באנגלית לפחות`
                    );
                    console.log("Password is not valid");
                  }
                } else {
                  alertUser("מייל חסר או לא תקין");
                  console.log("Mail is not valid");
                }
              } else {
                alertUser("מספר הטלפון לא תקין, המספר חייב להיות ישראלי");
                console.log("Phone number is not valid");
              }
            } else {
              alertUser(
                `שם משפחה לא תקין, אפשר שם בעברית או אנגלית בין ${MinNameCherecters} ל ${MaxNameCherecters} תווים`
              );
              console.log("not valid lastName");
            }
          } else {
            alertUser(
              `שם פרטי לא תקין, אפשר שם באנגלית או עברית בין ${MinNameCherecters} ל ${MaxNameCherecters} תווים`
            );
          }
        } else {
          alertUser(`נא למלא טלפון שלך של הרושמ/ת לאתר `);
        }
      } else {
        alertUser(
          `נא למלא שם שלך של הרושמ/ת לאתר, אפשר שם באנגלית או עברית בין ${MinNameCherecters} ל ${MaxNameCherecters} תווים`
        );
      }
    } else {
      alertUser("ברישום מועמד אחר חובה ליידע את המועמד");
    }
  };

  return (
    <form className="signup-form">
      <HeaderHomePage />
      <br />
      <br />
      <br />
      <div className="title" id="h1">
        הרשמה
      </div>
      <div className="signup-body">
        <div className="section-title">
          אל חשש, הפרטים אינם גלויים לכולם באתר! רק להתאמות רלוונטיות, וגם זה
          לזמן מוגבל.
        </div>
        <div>
          <input
            className="terms-and-conditions-checkbox"
            // className="exit-popup"
            type="checkbox"
            checked={isSignUpForAnother}
            onChange={() => setIsSignUpForAnother(!isSignUpForAnother)}
          />

          <div className="lbl" style={{ paddingTop: "8px" }}>
            ברצוני לרשום חבר/ה חדש/ה לאתר ולא את עצמי
          </div>
        </div>
        <div className={isSignUpForAnother ? "lbl" : "invisible"}>
          <b> איך זה עובד? </b>
          <br />
          רושמים חבר/ה לאתר, בונים כרטיס אודותיו ומגדירים מי יכול להתאים.
          <br />
          פעם בשבוע דאבל מבצע התאמות בין החברים לפי הכרטיסים שהגדירו, כאשר דאבל
          ימצא התאמה הוא ישלח הודעה ישירות למועמד עם פרטי ההתאמה.
          <br />
          זאת אומרת שאתם עכשיו רק פותחים כרטיס, את ההתאמות המועמד יקבל.
          <br />
          כי לקבל התאמות זה סבבה, לפתוח כרטיס זה פחות כיף,
          <b> ולכן טוב שאתם בתמונה </b>
          לפתוח כרטיס בשביל/ה. בשמחות :)
        </div>
        <input
          type="text"
          value={nameSignUpFriend}
          placeholder="שם מלא של הרושם לאתר"
          className={isSignUpForAnother ? "" : "invisible"}
          autocopmlete="off"
          onChange={(e) => setNameSignUpFriend(e.target.value)}
        ></input>
        <input
          type="text"
          value={cellSignUpFriend}
          placeholder="טלפון של הרושם לאתר"
          className={isSignUpForAnother ? "" : "invisible"}
          autocopmlete="off"
          onChange={(e) => setCellSignUpFriend(e.target.value)}
        ></input>
        <div className={isSignUpForAnother ? "" : "invisible"}>
          <div className="lbl">הקשר שלי למי שאני רושמת</div>
          <div style={{ width: "33.3%" }}>
            <fieldset style={{ marginTop: "-12px", marginLeft: "5px" }}>
              <div>
                <input
                  type="radio"
                  className="myRadio"
                  checked={myFamily}
                  name="meRadio"
                  onChange={onClickRelative}
                ></input>
                <label> משפחה</label>
              </div>
              <div>
                <input
                  type="radio"
                  className="myRadio"
                  checked={!myFamily}
                  name="meRadio"
                  onChange={onClickRelative}
                ></input>
                <label> חבר/ה </label>
              </div>
            </fieldset>
          </div>
          <div className={isSignUpForAnother ? "" : "invisible"}>
            <input
              className="terms-and-conditions-checkbox"
              // className="exit-popup"
              type="checkbox"
              checked={isThePersonKnow}
              onChange={() => setIsThePersonKnow(!isThePersonKnow)}
            />

            <div className="lbl" style={{ paddingTop: "8px" }}>
              המועמד/ת יודע/ת שאני רושמת אותו לאתר, ואשתמש במייל וטלפון שלו/ה
              בעת הרישום
            </div>
            <div className="lbl" style={{ paddingTop: "8px" }}>
              ועכשיו נמלא פרטים של המועדמ/ת:
            </div>
          </div>
        </div>
        <input
          type="text"
          id="firstName"
          value={firstName}
          placeholder="שם פרטי"
          autocopmlete="off"
          onChange={(e) => setFirstName(e.target.value)}
        ></input>
        <input
          type="text"
          id="lastName"
          value={lastName}
          placeholder="שם משפחה"
          autocopmlete="off"
          onChange={(e) => setLastName(e.target.value)}
        ></input>
        <input
          type="tel"
          id="cellular"
          value={telephone}
          placeholder="טלפון"
          maxLength={10}
          autocopmlete="off"
          onChange={(e) => setTelephone(e.target.value)}
        ></input>
        <input
          type="email"
          id="email"
          value={email}
          autocopmlete="off"
          placeholder='דוא"ל'
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <br />
        <br />
        <div className="section-title">
          {" "}
          סיסמה באורך 8 לפחות, תוים ומספרים (ללא תוים מיוחדים כמו @,# וכדומה)
        </div>
        <input
          // type="password"
          id="pwd"
          required
          value={password}
          autocopmlete="off"
          placeholder="סיסמה"
          onChange={(e) => {
            setIsErrorPasswordDisplayed(false);
            setPassword(e.target.value);
          }}
        ></input>
        {isErrorPasswordDisplayed && (
          <div className="error-message">
            <h5>{errorPassMsg} </h5>
          </div>
        )}
        <input
          // type="password"
          id="pwd1"
          value={confirmPassword}
          autocopmlete="off"
          placeholder="אימות סיסמה"
          onChange={(e) => {
            // if (!passwordValidator(password)) {
            //   setIsErrorPasswordDisplayed(true);
            // }
            if (
              password.length > maxPasswordLength ||
              password.length < minPasswordLength
            ) {
              setErrorPassMsg("שגיאה! סיסמה באורך לא תקין");
              setIsErrorPasswordDisplayed(true);
            } else if (passwordValidatorRegex.exec(password) == null) {
              setErrorPassMsg(
                "שגיאה! סיסמה לא יכולה להכיל תו מיוחד, רק אותיות באנגלית וספרות. וצריך לפחות אות אחת ולפחות סיפרה אחת"
              );
              setIsErrorPasswordDisplayed(true);
            }
            setConfirmPassword(e.target.value);
          }}
        ></input>
        <br />
        <div className="section-title">אני: </div>
        <div className="gender-buttons-container">
          <input
            type="button"
            className={`male ${gender === "2" ? "active" : ""} shadow`}
            // name="gender"
            value="גבר"
            key="2"
            onClick={(e) => handleGender("2")}
          />
          <input
            type="button"
            className={`female ${gender === "1" ? "active" : ""} shadow`}
            // name="gender"
            value="אישה"
            key="1"
            onClick={(e) => handleGender("1")}
          />
        </div>
        <input
          // type="password"
          value={referToWeb}
          autocopmlete="off"
          placeholder=" איך הגעתי לאתר"
          onChange={(e) => setReferToWeb(e.target.value)}
        ></input>
        {/* <div className="button" id="btnGetCode">
          {" "}
          לחצו לקבלת קוד
        </div>
        <input type="text" id="code" placeholder="כאן מכניסים את הקוד"></input> */}
        <div className="terms-and-conditions-contianer">
          {/* <input
            className="terms-and-conditions-checkbox"
            type="checkbox"
            checked={isTermsAndConditionsAgreed}
            onChange={() =>
              setIsTermsAndConditionsAgreed(!isTermsAndConditionsAgreed)
            }
          /> */}
          <h5
            style={{
              display: "inline",
              whiteSpace: "pre",
              textDecoration: "inherit",
            }}
          >
            {/* מאשר את&nbsp; */}
            <span
              className="terms-and-conditions-button "
              onClick={() => setIsTermsAndConditionsDisplayed(true)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              תנאי השימוש באתר
            </span>
          </h5>
        </div>
        <div className="button-text-center" id="btnSubmit" onClick={onSubmit}>
          אישור
        </div>
        <Link
          to="/questionnaires"
          style={{
            textDecoration: "none",
          }}
        >
          <div
            className="button"
            id="loginroute"
            style={{
              display: "none",
            }}
          >
            {" "}
            כניסה{" "}
          </div>{" "}
        </Link>{" "}
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}
        <Popup
          open={isTermsAndConditionsDisplayed}
          contentStyle={{
            minWidth: "30%",
            maxWidth: "60%",
            borderRadius: "5px",
          }}
          position="right center"
          onClose={() => setIsTermsAndConditionsDisplayed(false)}
          closeOnDocumentClick
          closeOnEscape
        >
          <TermsAndConditions />
          {/* <div className="terms-and-conditions-contianer"> */}
          <div>
            <input
              className="terms-and-conditions-checkbox"
              // className="exit-popup"
              type="checkbox"
              checked={isTermsAndConditionsAgreed}
              onChange={() =>
                setIsTermsAndConditionsAgreed(!isTermsAndConditionsAgreed)
              }
            />

            <h5
              style={{
                display: "inline",
                // whiteSpace: "pre-wrap",
                textDecoration: "inherit",
                // color: "red",
              }}
            >
              מאשר/ת את התנאים,
              <br />
              (התחתנתם מדאבל? ספרו לנו וקבלו שי לחתונה 🎁 💞 ){" "}
            </h5>
          </div>
          <div
            className="exit-popup"
            onClick={() => {
              setIsTermsAndConditionsDisplayed(false);
            }}
          >
            x
          </div>
          <br />

          <br />
        </Popup>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </div>
    </form>
  );
};

export default Signup;
