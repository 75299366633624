import React, { useState } from "react";
import "./MyPopup.css";
import { Link } from "react-router-dom";
import { getWordByGender } from "../../handlers/dictionaryHandler.js";

// import AdSense from "react-adsense";

const MyPopup = () => {
  const [isPyroOpen, setIsPyroOpen] = useState(true);

  const calculateTimeLeft = () => {
    let now = new Date();
    now.setDate(now.getDate() + ((0 + (7 - now.getDay())) % 7));
    let mm = now.getMonth() + 1;
    let x = now.getDate() + "/" + mm + "/" + now.getFullYear() + " ";
    return x;
  };

  return (
    <div
      className={isPyroOpen ? "hideScreen" : "invisible"}
      onClick={() => {
        setIsPyroOpen(false);
      }}
    >
      <div className={isPyroOpen ? "pyro" : "invisible"}>
        <div
          className="exit-popup"
          style={{ color: "white" }}
          onClick={() => {
            setIsPyroOpen(false);
          }}
        >
          x
        </div>
        <div className="popup_lbl" style={{ marginTop: "20px" }}>
          יש!!{" "}
        </div>
        <div className="popup_lbl">
          דאבל מצא השבוע {getWordByGender("someoneCan")} להתאים לך!
        </div>

        <Link to="/doublematch" style={{ textDecoration: "none" }}>
          <div
            className="popup_lbl lbl_link"
            onClick={localStorage.setItem("arriveFromPopup", 1)}
          >
            {"מעבר להתאמה-->"}
          </div>
        </Link>
        <div className="popup_lbl" style={{ fontSize: "small" }}>
          * אפשר להגיב להתאמה עד יום ראשון {calculateTimeLeft()}
          <br />
          גם הצד השני קיבל אתכם כהתאמה
        </div>

        <div class="before"></div>
        <div class="after"></div>
      </div>
    </div>
  );
};

export default MyPopup;
